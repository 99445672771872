<template>
  <div class="vg_wrapper">
    <publicTips></publicTips>
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm">
        <el-row>
          <el-col :md="8">
            <el-form-item label="样品编号:">
              <el-input size="small" v-model.trim="searchForm.smpl_no" clearable placeholder="请填写样品编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="样品名称:">
              <el-input size="small" v-model.trim="searchForm.smpl_name" clearable placeholder="请填写样品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="样品类型:">
              <el-select v-model="searchForm.smpl_type" placeholder="请选择样品类型" clearable size="small">
                <el-option v-for="item in smplType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="12">
            <el-form-item label="样品系列:">
              <el-input size="small" v-model.trim="searchForm.smpl_theme" clearable placeholder="请填写样品系列名"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getSmplsNow()" class="vg_ml_16">查询</el-button>
              <el-button size="small" type="info" icon="el-icon-refresh" @click="getSmplsNowO()" class="vg_ml_16">刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="vd_mar10">
      <el-col :md="8">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
        <el-link type="primary" @click="goSmpl" class="vg_cursor"><i class="el-icon-edit-outline"></i>样品总控表管理</el-link>
      </el-col>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loading"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="委托单号" prop="requ_no" />
          <el-table-column label="样品编号" prop="smpl_no" />
          <el-table-column label="客户货号" prop="smpl_cust_no">
            <template slot-scope="scope">
              <div v-if="scope.row.smpl_cust_no">
                {{ scope.row.smpl_cust_no }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="样品名称" prop="smpl_name" />
          <el-table-column label="样品系列名" prop="smpl_theme">
            <template slot-scope="scope">
              <div v-if="scope.row.smpl_theme">
                {{ scope.row.smpl_theme }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="开版师" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.smpl_stff_name">
                {{ scope.row.smpl_stff_name }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="样品类型" prop="smpl_type" :formatter="formatSmplType" show-overflow-tooltip />
          <el-table-column label="样品间报价">
            <template slot-scope="scope">
              <div v-if="scope.row.smpl_price">
                {{ scope.row.smpl_price | foamatSmplPrice }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="采购部核价" prop="smpl_verify_price">
            <template slot-scope="scope">
              <div v-if="scope.row.smpl_verify_price">
                {{ scope.row.smpl_verify_price | formatSmplVerifyPrice }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="样品数量" prop="smpl_num">
            <template slot-scope="scope">
              <div v-if="scope.row.smpl_num || scope.row.smpl_num === 0">
                {{ scope.row.smpl_num | formatSmplNum }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="实际交样时间" prop="smpl_deil_time" :formatter="formatDate1" show-overflow-tooltip/> -->
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { smplAPI } from '@api/modules/smpl';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';

export default {
  name: 'SmplEditBom',
  components: {
    pubPagination,
    publicTips
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        smpl_name: '',
        smpl_no: '',
        smpl_type: '',
        status: null,
        smpl_theme: ''
      },
      totalPage: 0,
      btn: {},
      loading: true,
      multiSelection: [],
      currentPage: 1,
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ]
    };
  },
  created() {
    this.initData();
  },
  filters: {
    foamatSmplPrice(row) {
      return helper.haveFour(row);
    },
    formatSmplVerifyPrice(row) {
      return helper.haveFour(row);
    },
    formatSmplNum(row) {
      return helper.haveFour(row);
    }
  },
  methods: {
    initData() {
      this.getSmplsList();
    },
    // 获取物料信息
    getSmplsList() {
      get(smplAPI.getSmpls, {
        smpl_theme: this.searchForm.smpl_theme,
        smpl_no: this.searchForm.smpl_no,
        smpl_name: this.searchForm.smpl_name,
        smpl_type: this.searchForm.smpl_type,
        status: 2,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            this.loading = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getSmplsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getSmplsList();
    },
    // 刷新
    getSmplsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      (this.searchForm = {
        smpl_name: '',
        smpl_no: '',
        smpl_type: '',
        smpl_theme: '',
        status: null
      }),
        this.getSmplsList();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.smpl_id;
    },
    // //新增
    goSmpl() {
      this.$emit('falseSmpl');
      this.jump(`/smpl_list?perm_id=${this.helper.retPermId('smpl')}`);
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getSmplsList();
    },
    // 时间转换
    formatDate1(row) {
      return this.helper.toStringDate(row.smpl_deil_time);
    },
    // 样品类型转换
    formatSmplType(row) {
      if (row.smpl_type === 1) {
        return '新款';
      } else if (row.smpl_type === 2) {
        return '老款';
      } else if (row.smpl_type === 3) {
        return '修改款';
      }
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childSmplChanel');
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childSmpl', this.multiSelection);
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    },
    // 选未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt {
  margin-top: 20px;
}
.vd_mar10 {
  margin: 10px 0;
}
</style>
